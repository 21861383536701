<template>
  <div style="width: 100%">
    <crud-md
      :delete-confirm-text="deleteConfirmCrudText"
      :default-criteria="defaultCriteria"
    ></crud-md>
  </div>
</template>

<script>
export default {
  components: {
    CrudMd: () => import("@/components/crud-md"),
  },
  data: () => ({
    deleteConfirmCrudText: {
      single:
        "Si eliminas esta formación, se eliminará toda la información relacionada.<br /><br /><strong>DOCUMENTOS INCLUIDOS</strong><br /><br /> ¿Quieres eliminar esta formación?",
      multiple:
        "Si eliminas estas formaciones, se eliminará toda la información relacionada.<br /><br /><strong>DOCUMENTOS INCLUIDOS</strong><br /><br /> ¿Quieres eliminar estas formaciones?",
    },
    defaultCriteria: [
      {
        field: "start_date",
        operator: "btw",
        value: [],
      },
    ],
  }),
  beforeMount() {
    const today = new Date();
    const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    const [start_date_From] = oneYearAgo.toISOString().split("T");

    const threeMonthsLater = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate());
    const [start_date_to] = threeMonthsLater.toISOString().split("T");

    this.defaultCriteria[0].value = [start_date_From, start_date_to];
  },
};
</script>
